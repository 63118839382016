<div class="d-flex align-items-center">
  <div *ngIf="status === 'ON'" class="rounded-circle size15x15 bg-green"></div>
  <div *ngIf="status === 'Rescheduled'">
    <img class="rounded-circle size15x15" src="/assets/img/r-circle.svg" />
  </div>
  <div *ngIf="status === 'Automatic'">
    <div *ngIf="statusNum === 0" class="rounded-circle size15x15 bg-blue"></div>
    <div *ngIf="statusNum === 1">
      <img
        class="rounded-circle size15x15"
        src="/assets/img/statuses/1_4-yellow.png"
      />
    </div>
    <div *ngIf="statusNum === 2">
      <img
        class="rounded-circle size15x15"
        src="/assets/img/statuses/half-yellow.png"
      />
    </div>
    <div *ngIf="statusNum === 3">
      <img
        class="rounded-circle size15x15"
        src="/assets/img/statuses/3_4-yellow.png"
      />
    </div>
    <div *ngIf="statusNum === 4">
      <img
        class="rounded-circle size15x15"
        src="/assets/img/statuses/half-green.png"
      />
    </div>
  </div>
  <div
    *ngIf="status === 'Full'"
    class="d-flex justify-content-center align-items-center size15x15"
  >
    <strong class="full-check">F</strong>
  </div>
  <div *ngIf="status === 'Ready'">
    <img
      class="rounded-circle size15x15"
      src="/assets/img/statuses/half-green.png"
    />
  </div>
  <div *ngIf="status === 'Pending' || (!status && !isMonthStatus)">
    <img
      class="rounded-circle size15x15"
      src="/assets/img/statuses/half-blue.png"
    />
  </div>
  <div *ngIf="status === 'Few Spots' && tabSelected == 'to-Do'">
    <div *ngIf="!statusNum">
      <div class="rounded-circle size15x15 nullStat"></div>
    </div>
    <div *ngIf="statusNum === 1">
      <img
        class="rounded-circle size15x15"
        src="/assets/img/arrows/crevron-up.svg"
      />
    </div>
    <div *ngIf="statusNum === 2">
      <img
        class="rounded-circle size15x15"
        src="../../../../../assets/img/arrows/crevron-down.svg"
      />
    </div>
  </div>
  <div *ngIf="status === 'Few Spots' && tabSelected !== 'to-Do'">
    <div class="rounded-circle size15x15 nullStat"></div>
  </div>
  <div *ngIf="!status && isMonthStatus">
    <div class="rounded-circle size15x15 nullStat"></div>
  </div>
  <div *ngIf="status === 'OFF'" class="rounded-circle size15x15 bg-gray"></div>
  <div *ngIf="status === 'Cancelled'">
    <img class="rounded-circle size15x15" src="/assets/img/x_15x15_black.svg" />
  </div>
  <div *ngIf="status === 'Consolidated'">
    <img class="rounded-circle size15x15" src="/assets/img/c_15x15_black.svg" />
  </div>
</div>

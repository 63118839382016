import { Component, EventEmitter, Output } from '@angular/core';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { IScreenOptions } from 'src/app/state/companies.repository';
@Component({
  selector: 'app-search-modal-absolute',
  templateUrl: './search-modal-absolute.component.html',
  styleUrls: ['./search-modal-absolute.component.scss'],
})
export class SearchModalAbsoluteComponent {
  expanded: boolean = false;
  myOptions: any;
  @Output() ChangeCenterSubmit = new EventEmitter<IScreenOptions>();
  @Output() clear = new EventEmitter();

  ChangeLocationMapBySearch(address: any) {
    let lng = address?.geometry?.location?.lng();
    let lat = address?.geometry?.location?.lat();
    this.ChangeCenterSubmit.emit({
      lat,
      lng,
      zoom: 10,
    });
  }

  ClearMapSearch() {}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  type EditorConfig,
  ClassicEditor,
  Autosave,
  Bold,
  Essentials,
  Heading,
  Italic,
  Link,
  List,
  Paragraph,
  Underline,
  FontSize,
  Alignment,
} from 'ckeditor5';
const LICENSE_KEY = 'GPL';
@Component({
  selector: 'app-text-editor-modal',
  templateUrl: './text-editor-modal.component.html',
  styleUrls: ['./text-editor-modal.component.scss'],
})
export class TextEditorModalComponent implements OnInit {
  public Editor = ClassicEditor;
  public config: EditorConfig = {};
  isModalOpen = false;
  fadeOut = false;
  isInvalid = false;
  @Input() editorContent: string = '';
  editedContent: string = '';
  @Input() forImageUrl = false;
  @Input() forTextOnly = false;
  @Input() title: string = 'Field edit';
  titleForId = this.title.replace(/[\s\/-]/g, '').toLowerCase();
  @Input() largeTextField = false;
  @Input() forDisclaimer = false;
  disclaimerActive = false;
  @Output() text: EventEmitter<string> = new EventEmitter();
  ngOnInit(): void {
    this.config = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          '|',
          'alignment',
          'bulletedList',
          'numberedList',
          '|',
          'link',
        ],
        shouldNotGroupWhenFull: false,
      },
      plugins: [
        Autosave,
        Bold,
        Essentials,
        Heading,
        Italic,
        Link,
        List,
        Paragraph,
        Underline,
        FontSize,
        Alignment,
      ],
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2',
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3',
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4',
          },
        ],
      },
      licenseKey: LICENSE_KEY,
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
      },
      placeholder: this.title,
    };
  }

  openModal(): void {
    this.isInvalid = false;
    this.isModalOpen = true;
    this.fadeOut = false;
    this.editedContent = this.editorContent;
    if (this.forDisclaimer) {
      this.disclaimerActive = this.editedContent.includes('<small>');
    }
    this.editedContent = this.editedContent
      .replace('<small>', '')
      .replace('</small>', '');
  }

  onReady() {
    if (this.forDisclaimer) {
      const headingDropdown = document.querySelector('.ck-heading-dropdown');
      headingDropdown?.insertAdjacentHTML(
        'afterend',
        `<button class="ck ck-button ${
          this.disclaimerActive ? 'ck-on' : ''
        } disclaimer-button" id="disclaimerButton" type="button"><p class="disclaimer-button-text">D</p></button>`
      );
      const button = document.getElementById('disclaimerButton');

      button?.addEventListener('click', () => {
        this.disclaimerActive = !this.disclaimerActive;
        button.classList.toggle('ck-on');
      });
    }
  }
  log() {
    console.log('clicked');
  }
  closeModal(): void {
    this.fadeOut = true;

    setTimeout(() => {
      this.isModalOpen = false;
    }, 300);
  }

  save(): void {
    if (!this.forImageUrl && !this.forTextOnly) {
    } else if (this.forImageUrl) {
      this.validateInput();
      if (this.isInvalid) return;
    }
    if (this.disclaimerActive)
      this.editedContent = '<small>' + this.editedContent + '</small>';
    this.text.emit(this.editedContent);
    this.closeModal();
  }

  validateInput() {
    this.isInvalid =
      !!this.editedContent && !this.isValidUrl(this.editedContent);
  }

  isValidUrl(value: string): boolean {
    const urlPattern = new RegExp(
      /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/.*)?$/
    );
    return !!value && urlPattern.test(value);
  }
}

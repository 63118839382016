import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthRepository } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { TenantFeatures } from 'src/app/state/feature.repository';
import { UiRepository } from 'src/app/state/ui.repository';
import {
  DocumentTypesRepository,
  IDocumentType,
} from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import { UsersService } from 'src/app/state/users.service';
import { EventScheduleRepository } from '../../../../state/event-schedule.repository';
import { EventScheduleService } from 'src/app/state/event-schedule.service';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { ICompanyMarker } from 'src/app/state/companies.repository';
import { CompaniesService } from 'src/app/state/companies.service';
import { ClientUsersService } from 'src/app/state/clientUsers.service';
import { RealTimeClientService } from 'src/app/state/signalR/real-time-client.service';
import { TicketsRepository } from 'src/app/state/tickets/tickets.repository';
import { TicketsService } from 'src/app/state/tickets/tickets.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  TenantFeatures = TenantFeatures;
  features = this.env.features;
  clientFolder?: string;

  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    public service: UsersService,
    public ui: UiRepository,
    public router: Router,
    public env: EnvState,
    public doctypesRepo: DocumentTypesRepository,
    public docttpeService: DocumentTypesService,
    public companiesService: CompaniesService,
    public clientUsersService: ClientUsersService,
    public esRepo: EventScheduleRepository,
    public realTimeService: RealTimeClientService,
    public ticketsRepo: TicketsRepository,
    public ticketsService: TicketsService,
    public esService: EventScheduleService
  ) {
    realTimeService.ordersUpdated$.subscribe((x) => this.logout());
    this.reloadPendingEventsInfo();
    //#tickets_system
    this.reloadTicketsInfo();
  }
  clientsOptions: DatalistOption[] | null = null;
  @Input() set clients(value: ICompanyMarker[] | null) {
    if (!value) {
      this.clientsOptions = null;
    } else {
      this.clientsOptions = value.map((x) => ({
        value: x.id,
        label: x.title,
      }));
    }
  }
  isClientUser = false;
  ngOnInit(): void {
    this.isClientUser = this.repo.getIsClientUser();

    if (this.isClientUser) {
      this.companiesService.clientUserActive().subscribe((url) => {
        this.clients = url;
      });
    }
  }

  menuclose() {
    this.ui.toggleMenuOpened(false);
  }

  logout() {
    this.menuclose();
    this.auth.logout().subscribe(() => this.router.navigate(['/signin']));
    return false; // prevent default
  }
  openSpreadSheetInNewTab() {
    this.clientFolder = this.repo.getFolder() ?? '';
    if (this.clientFolder) {
      if (!this.clientFolder.startsWith('http')) {
        this.clientFolder = '//' + this.clientFolder;
      }
      window.open(this.clientFolder, '_blank');
    }
  }

  unimpersonate() {
    this.menuclose();
    this.auth.unimpersonate().subscribe(() => this.router.navigate(['/']));
    return false; // prevent default
  }

  filterShown(arr: IDocumentType[] | null) {
    return arr?.filter((x) => x.isShownOnSideMenu);
  }
  reloadPendingEventsInfo() {
    if (!this.isClientUser) this.esService.getPendingEventsCount().subscribe();
  }
  reloadTicketsInfo() {
    //#tickets_system
    if (!this.isClientUser) this.ticketsService.getTicketsCount().subscribe();
  }
  changeActiveClient($event: string) {
    this.auth.changeActiveClient($event).subscribe({
      error: (e) => console.error(e),
      complete: () => this.router.navigateByUrl('/'),
    });
  }
  routerUrlIncludesAny(...args: string[]): boolean {
    return args.some((x) => this.router.url.includes(x));
  }
}

<div>
  <div class="d-flex justify-content-between px-2 h60 last-event">
    <div
      class="d-flex align-items-center"
      style="width: 50px"
      type="button"
      (click)="
        enterChangeStatus.emit({
          entityId: event.id,
          parentEntityId: mainParentId,
          date: 1,
          pageX: $event.pageX,
          pageY: $event.pageY
        })
      "
    >
      <app-event-status
        [status]="event.status"
        [statusNum]="+event.statusNum"
      ></app-event-status>
    </div>
    <div class="d-flex align-items-center" style="width: 40px">
      <input
        type="checkbox"
        class="form-check-input dotsBinding"
        color="orange"
        [(ngModel)]="event.orangeDot"
        [checked]="event.orangeDot"
        (change)="
          checkDot.emit({
            entityId: event.id,
            type: 'orange',
            doubleEvent: false,
            value: event.orangeDot,
            event: $event
          })
        "
      />
    </div>
    <div class="d-flex align-items-center" style="width: 40px">
      <input
        type="checkbox"
        class="form-check-input dotsBinding"
        color="pink"
        [(ngModel)]="event.pinkDot"
        [checked]="event.pinkDot"
        (change)="
          checkDot.emit({
            entityId: event.id,
            type: 'pink',
            doubleEvent: false,
            value: event.pinkDot,
            event: $event
          })
        "
      />
    </div>

    <div class="d-flex align-items-center" style="width: 100px">
      <div
        class="text-break oneLineText pointer ccBinding"
        data-bs-toggle="modal"
        (click)="showModal.emit(event.id)"
        data-bs-target="#eventSnapshotModal"
      >
        {{ event.client.clientCode }}
        <br />
      </div>
    </div>
    <div style="width: 500px">
      <div
        class="d-flex align-items-center"
        [style.paddingLeft.px]="paddStart"
        [class.treeview-menu]="paddStart"
        (click)="goToLink(event.clientUrl)"
        [class.treeview-menu-half]="event.firstReschedules?.length"
      >
        <div class="text-break oneLineText treeview-item">
          <span
            [class.client_blue]="event.wfrDinnerOrLunch"
            [class.color-orange]="event.twoPartSetup"
            >{{ event.client.title }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center"
      [class.double-session-first]="event.doubleSession"
      style="width: 300px"
    >
      <div class="text-break oneLineText">
        {{ event.formatedDateAdmin }}
        <span>{{
          event.atCost && event.atCost != "No" ? "(" + event.atCost + ")" : ""
        }}</span>

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        <span
          [class.enableUrl]="event.landingPageUrl"
          (click)="goToLink(event.landingPageUrl)"
        >
          {{ event.eventScheduleLocation?.title ?? "" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center pointer" style="min-width: 100px">
      <div
        class="d-flex align-items-center justify-content-end flex-column py-2"
        title="Registrants"
      >
        <div class="d-flex align-items-center">
          <a
            class="btn btn-link d-flex p-0 m-0 regBinding"
            title="Registrants"
            i18n-title="Registrants button"
            (click)="goToLink(event.googleSheet)"
          >
            <span class="me-1 p-0 m-0" style="color: black">
              <sup class="p-0 m-0"> {{ +event.attendees }} </sup>/<sub
                class="me-1 p-0 m-0"
              >
                {{
                  +(event.finalRegistrants
                    ? event.finalRegistrants
                    : event.registrants)
                }}
              </sub>

              <span>
                ({{
                  this.setMax100(
                    event.attendees,
                    +(event.finalRegistrants
                      ? event.finalRegistrants
                      : event.registrants)
                  )
                }}%)
              </span>
            </span>
          </a>
        </div>
      </div>

      <br />
    </div>

    <div
      class="d-flex align-items-center justify-content-end"
      style="min-width: 100px; right: 0"
    >
      <!-- <div>
        <button
          type="button "
          class="btn activityLogsBinding p-0 pe-1 m-0"
          data-bs-toggle="modal"
          (click)="enterActivityLogs.emit(event.id)"
          data-bs-target="#activityLogModal"
          title="Activity Logs"
        >
          <img src="assets/img/qeustion-circle.svg" />
        </button>
      </div> -->
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
            title="Action"
            (click)="
              actionPop.emit({
                event: event,
                date: 1,
                left: $event.pageX,
                top: $event.pageY
              })
            "
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div>
        <!-- <div>
          <a
            class="btn btn-link w-100 p-0 m-0"
            title="Edit"
            [routerLink]="['/admin/eventSchedule/eventSchedule/', event.id]"
          >
            <img class="editBinding" src="assets/img/edit19x19.svg" />
          </a>
        </div>
        <div>
          <button
            (click)="deleteConfirmation.emit({ entity: event, date: 1 })"
            class="btn btn-link w-100 p-0 px-1 m-0"
            data-bs-target="#esDeleteConfirmModal"
            title="Delete"
          >
            <img
              type="button"
              class="deleteBinding"
              src="assets/img/delete18x20.svg"
            />
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div *ngIf="event.firstReschedules.length">
    <div *ngFor="let firstEv of event.firstReschedules">
      <app-es-overview-double-row
        (checkDot)="checkDot.emit($event)"
        (enterChangeStatus)="enterChangeStatus.emit($event)"
        (enterActivityLogs)="enterActivityLogs.emit($event)"
        (deleteConfirmation)="deleteConfirmation.emit($event)"
        [mainParentId]="mainParentId"
        [paddStart]="paddStart + 20"
        [event]="firstEv"
        (enterPayStats)="enterPayStats.emit($event)"
        (showModal)="showModal.emit($event)"
        (actionPop)="actionPop.emit($event)"
      ></app-es-overview-double-row>
    </div>
  </div>

  <div
    class="d-flex justify-content-between px-2 h60 last-event"
    *ngIf="event.doubleEvent || event.doubleSession"
  >
    <div
      class="d-flex align-items-center"
      style="width: 50px"
      type="button"
      (click)="
        enterChangeStatus.emit({
          entityId: event.id,
          parentEntityId: mainParentId,
          date: 2,
          pageX: $event.pageX,
          pageY: $event.pageY
        })
      "
    >
      <app-event-status
        [status]="event.statusD2"
        [statusNum]="+event.statusNumD2"
      ></app-event-status>
    </div>

    <div class="d-flex align-items-center" style="width: 40px">
      <input
        type="checkbox"
        class="form-check-input dotsBinding"
        color="orange"
        [(ngModel)]="event.orangeDotD2"
        [checked]="event.orangeDotD2"
        (change)="
          checkDot.emit({
            entityId: event.id,
            type: 'orange',
            doubleEvent: true,
            value: event.orangeDotD2,
            event: $event
          })
        "
      />
    </div>
    <div class="d-flex align-items-center" style="width: 40px">
      <input
        type="checkbox"
        class="form-check-input dotsBinding"
        color="pink"
        [(ngModel)]="event.pinkDotD2"
        [checked]="event.pinkDotD2"
        (change)="
          checkDot.emit({
            entityId: event.id,
            type: 'pink',
            doubleEvent: true,
            value: event.pinkDotD2,
            event: $event
          })
        "
      />
    </div>

    <div class="d-flex align-items-center" style="width: 100px">
      <div
        class="text-break oneLineText pointer ccBinding"
        data-bs-toggle="modal"
        (click)="showModal.emit(event.id)"
        data-bs-target="#eventSnapshotModal"
      >
        {{ event.client.clientCode }}
        <br />
      </div>
    </div>
    <div style="width: 500px">
      <div
        class="d-flex align-items-center"
        [style.paddingLeft.px]="paddStart"
        [class.treeview-menu]="paddStart"
        [class.treeview-menu-half]="event.secondReschedules?.length"
        (click)="goToLink(event.clientUrl)"
      >
        <div class="text-break oneLineText treeview-item">
          <span
            [class.client_blue]="event.wfrDinnerOrLunch"
            [class.color-orange]="event.twoPartSetup"
            >{{ event.client.title }}</span
          >
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        {{ event.formatedDate2Admin }}
        <span>{{
          event.atCostD2 && event.atCostD2 != "No"
            ? "(" + event.atCostD2 + ")"
            : ""
        }}</span>

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        <span
          [class.enableUrl]="
            event.landingPageUrl2 ||
            (event.doubleSession && event.landingPageUrl)
          "
          (click)="
            goToLink(
              event.doubleSession ? event.landingPageUrl : event.landingPageUrl2
            )
          "
        >
          {{ event.eventScheduleLocation?.title ?? "" }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center pointer" style="min-width: 100px">
      <div
        class="d-flex align-items-center justify-content-end flex-column py-2"
        title="Registrants"
      >
        <div class="d-flex align-items-center">
          <a
            class="btn btn-link d-flex p-0 m-0 regBinding"
            title="Registrants"
            i18n-title="Registrants button"
            (click)="goToLink(event.googleSheetD2)"
          >
            <span class="me-1 p-0 m-0" style="color: black">
              <sup class="p-0 m-0"> {{ +event.attendeesD2 }} </sup>/<sub
                class="me-1 p-0 m-0"
              >
                {{
                  +(event.finalRegistrantsD2
                    ? event.finalRegistrantsD2
                    : event.registrantsD2)
                }}
              </sub>

              <span>
                ({{
                  this.setMax100(
                    event.attendeesD2,
                    +(event.finalRegistrantsD2
                      ? event.finalRegistrantsD2
                      : event.registrantsD2)
                  )
                }}%)
              </span>
            </span>
          </a>
        </div>
      </div>

      <br />
    </div>
    <div
      class="d-flex align-items-center justify-content-end"
      style="min-width: 100px; right: 0"
    >
      <!-- <div>
        <button
          type="button "
          class="btn activityLogsBinding p-0 m-0 pe-1"
          data-bs-toggle="modal"
          (click)="enterActivityLogs.emit(event.id)"
          data-bs-target="#activityLogModal"
          title="Activity Logs"
        >
          <img src="assets/img/qeustion-circle.svg" />
        </button>
      </div> -->
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
            title="Action"
            (click)="
              actionPop.emit({
                event: event,
                date: 2,
                left: $event.pageX,
                top: $event.pageY
              })
            "
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div>
        <!-- <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div> -->
        <!-- <div>
          <a
            class="btn btn-link w-100 p-0 m-0"
            title="Edit"
            [routerLink]="['/admin/eventSchedule/eventSchedule/', event.id]"
          >
            <img class="editBinding" src="assets/img/edit19x19.svg" />
          </a>
        </div>
        <div>
          <button
            (click)="deleteConfirmation.emit({ entity: event, date: 2 })"
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0"
            data-bs-target="#esDeleteConfirmModal"
            title="Delete"
          >
            <img class="deleteBinding" src="assets/img/delete18x20.svg" />
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div *ngIf="event.secondReschedules.length">
    <div *ngFor="let secondEv of event.secondReschedules">
      <app-es-overview-double-row
        (checkDot)="checkDot.emit($event)"
        (enterChangeStatus)="enterChangeStatus.emit($event)"
        (enterActivityLogs)="enterActivityLogs.emit($event)"
        (deleteConfirmation)="deleteConfirmation.emit($event)"
        [mainParentId]="mainParentId"
        [paddStart]="paddStart + 20"
        [event]="secondEv"
        (enterPayStats)="enterPayStats.emit($event)"
        (showModal)="showModal.emit($event)"
        (actionPop)="actionPop.emit($event)"
      ></app-es-overview-double-row>
    </div>
  </div>
</div>

<div>
  <div
    class="d-flex justify-content-between p-2 h60 b-top-gray last-event"
    [class.removeDoubleRow]="
      (event.doubleEvent && !event.firstReschedules.length) ||
      event.doubleSession
    "
    [class.col-orange]="event.status === 'Rescheduled'"
    [class.text-danger]="event.status === 'Cancelled'"
    [class.b-top-gray]="eventIndx !== 0"
  >
    <div
      class="d-flex align-items-center pointer"
      style="width: 220px"
      (click)="mouseEnterPayStats($event, event.id, mainParentId)"
    >
      <div class="text-break oneLineText">
        <span
          [class.col-orange]="
            (event.atCost && event.atCost != 'No') ||
            event.status === 'Rescheduled'
          "
          [class.text-danger]="event.status === 'Cancelled'"
        >
          {{ event.payStats }}
        </span>
        <br />
      </div>
    </div>

    <div class="d-flex align-items-center" style="width: 150px">
      <div
        class="text-break oneLineText pointer"
        data-bs-toggle="modal"
        (click)="showModal.emit(event.id)"
        data-bs-target="#eventSnapshotModal"
      >
        {{ event.client.clientCode }}
        <br />
      </div>
    </div>
    <div style="width: 500px">
      <div
        class="d-flex align-items-center"
        [style.paddingLeft.px]="paddStart"
        [class.treeview-menu]="paddStart"
        [class.treeview-menu-half]="event.firstReschedules?.length"
        (click)="goToLink(event.clientUrl)"
      >
        <div class="text-break oneLineText treeview-item">
          <span
            [class.client_blue]="event.wfrDinnerOrLunch"
            [class.color_orange]="event.twoPartSetup"
            >{{ event.client.title }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center"
      style="width: 350px"
      [class.double-session-first]="event.doubleSession"
    >
      <div class="text-break oneLineText">
        {{ event.formatedDateAdmin }}
        <span *ngIf="event.atCost && event.atCost != 'No'" class="ps-1"
          >({{ event.atCost }})</span
        >

        <br />
      </div>
    </div>

    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        <span
          [class.blueLink]="event.landingPageUrl"
          (click)="goToLink(event.landingPageUrl)"
          [class.col-orange]="event.status === 'Rescheduled'"
          [class.text-danger]="event.status === 'Cancelled'"
        >
          {{ event.eventScheduleLocation?.title }}
        </span>
      </div>
    </div>

    <div class="d-flex align-items-center pointer" style="min-width: 100px">
      <div
        class="d-flex align-items-center justify-content-end flex-column"
        title="Registrants"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div
            class="d-flex p-0 m-0"
            (click)="goToLink(event.googleSheet)"
            title="Registrants"
            i18n-title="Registrants button"
          >
            <span class="me-1 p-0 m-0">
              <sup class="p-0 m-0"> {{ +event.attendees }} </sup>/<sub
                class="me-1 p-0 m-0"
              >
                {{
                  +(event.finalRegistrants
                    ? event.finalRegistrants
                    : event.registrants)
                }}
              </sub>
              <span>
                ({{
                  setMax100(
                    event.attendees,
                    +(event.finalRegistrants
                      ? event.finalRegistrants
                      : event.registrants)
                  )
                }}%)
              </span>
            </span>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div
      class="d-flex align-items-center justify-content-center doubleRowM"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center doubleRowP">
        {{ event.metaAds }}
        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center doubleRowM"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center doubleRowP">
        {{ event.otherAds }}

        <br />
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-center doubleRowM"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center doubleRowP">
        {{ event.metaAds + event.otherAds }}

        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center doubleRowM"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center doubleRowP">
        {{ event.client.feePerEvent }}

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center doubleRowM" style="width: 300px">
      <div class="text-break d-flex align-items-center doubleRowP">
        <!-- Invoiced Ads -->
        <input
          class="tableInpt"
          type="number"
          [(ngModel)]="event.invoicedAds"
          (change)="calcFinance(event)"
          placeholder="0"
        />

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center doubleRowM" style="width: 200px">
      <div class="text-break d-flex align-items-center doubleRowP">
        <!-- Qts -->
        <input
          class="tableInpt"
          type="number"
          [(ngModel)]="event.qts"
          (change)="calcFinance(event)"
          placeholder="0"
        />

        <br />
      </div>
    </div>
    <div class="d-flex align-items-center doubleRowM" style="width: 300px">
      <div class="text-break d-flex align-items-center doubleRowP">
        <!-- Flat Fee -->
        <input
          class="tableInpt"
          type="number"
          [(ngModel)]="event.flatFee"
          (change)="calcFinance(event)"
          placeholder="0"
        />

        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center doubleRowM"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center doubleRowP">
        {{ event.totalInvoices }}
        <br />
      </div>
    </div>

    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText" [title]="event.adSpentNotes">
        <span>
          {{ event.adSpentNotes }}
        </span>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-center doubleRowM"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center doubleRowP">
        {{ event.costLead }}

        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center doubleRowM"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center doubleRowP">
        {{ event.grossProfit }}
        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-end"
      style="min-width: 50px"
    >
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <div>
            <button
              type="button"
              class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
              title="Action"
              (click)="
                actionPop.emit({
                  event: event,
                  date: 1,
                  left: $event.pageX,
                  top: $event.pageY
                })
              "
            >
              <img src="assets/img/pointer/pointer.svg" />
            </button>
          </div>
        </div>
        <!-- <div>
          <a
            class="btn btn-link w-100 p-0 m-0"
            [routerLink]="['/admin/eventSchedule/eventSchedule/', event.id]"
            title="Edit"
            i18n-title="Edit button"
          >
            <img src="assets/img/edit19x19.svg" />
          </a>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0"
            data-bs-target="#esDeleteConfirmModal"
            (click)="deleteConfirmation.emit({ entity: event, date: 1 })"
            title="Delete"
            i18n-title="Delete button"
          >
            <img src="assets/img/delete18x20.svg" />
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div *ngIf="event.firstReschedules?.length">
    <div *ngFor="let firstEv of event.firstReschedules">
      <app-es-finance-double-row
        [event]="firstEv"
        (calcFinances)="calcFinances.emit($event)"
        [paddStart]="paddStart + 20"
        [mainParentId]="mainParentId"
        (deleteConfirmation)="deleteConfirmation.emit($event)"
        (enterPayStats)="enterPayStats.emit($event)"
        (showModal)="showModal.emit($event)"
        (actionPop)="actionPop.emit($event)"
      ></app-es-finance-double-row>
    </div>
  </div>
  <div
    *ngIf="event.doubleEvent || event.doubleSession"
    class="d-flex justify-content-between p-2 h60 last-event"
    [class.col-orange]="event.statusD2 === 'Rescheduled'"
    [class.text-danger]="event.statusD2 === 'Cancelled'"
    [class.removeDoubleRow]="
      event.doubleEvent && event.secondReschedules.length
    "
  >
    <div
      class="d-flex align-items-center pointer"
      style="width: 220px"
      (click)="mouseEnterPayStats($event, event.id, mainParentId)"
    >
      <div class="text-break oneLineText">
        <span
          [class.col-orange]="
            (event.atCostD2 && event.atCostD2 != 'No') ||
            event.statusD2 === 'Rescheduled'
          "
          [class.text-danger]="event.statusD2 === 'Cancelled'"
        >
          {{ event.payStats }}
        </span>
        <br />
      </div>
    </div>

    <div class="d-flex align-items-center" style="width: 150px">
      <div
        class="text-break oneLineText pointer"
        data-bs-toggle="modal"
        (click)="showModal.emit(event.id)"
        data-bs-target="#eventSnapshotModal"
      >
        {{ event.client.clientCode }}
        <br />
      </div>
    </div>
    <div style="width: 500px">
      <div
        class="d-flex align-items-center"
        [style.paddingLeft.px]="paddStart"
        [class.treeview-menu]="paddStart"
        [class.treeview-menu-half]="event.secondReschedules?.length"
        (click)="goToLink(event.clientUrl)"
      >
        <div class="text-break oneLineText treeview-item">
          <span
            [class.client_blue]="event.wfrDinnerOrLunch"
            [class.color_orange]="event.twoPartSetup"
            >{{ event.client.title }}</span
          >
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 350px">
      <div class="text-break oneLineText">
        {{ event.formatedDate2Admin }}
        <span *ngIf="event.atCostD2 && event.atCostD2 != 'No'" class="ps-1"
          >({{ event.atCostD2 }})</span
        >

        <br />
      </div>
    </div>

    <div class="d-flex align-items-center" style="width: 500px">
      <div class="text-break oneLineText">
        <span
          [class.blueLink]="
            event.landingPageUrl2 ||
            (event.doubleSession && event.landingPageUrl)
          "
          (click)="
            goToLink(
              event.doubleSession ? event.landingPageUrl : event.landingPageUrl2
            )
          "
          [class.col-orange]="event.statusD2 === 'Rescheduled'"
          [class.text-danger]="event.statusD2 === 'Cancelled'"
        >
          {{ event.eventScheduleLocation?.title }}
        </span>
      </div>
    </div>

    <div class="d-flex align-items-center pointer" style="min-width: 100px">
      <div
        class="d-flex align-items-center justify-content-end flex-column py-2"
        title="Registrants"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div
            class="d-flex p-0 m-0"
            (click)="goToLink(event.googleSheetD2)"
            title="Registrants"
            i18n-title="Registrants button"
          >
            <span class="me-1 p-0 m-0">
              <sup class="p-0 m-0"> {{ +event.attendeesD2 }} </sup>/<sub
                class="me-1 p-0 m-0"
              >
                {{
                  +(event.finalRegistrantsD2
                    ? event.finalRegistrantsD2
                    : event.registrantsD2)
                }}
              </sub>
              <span>
                ({{
                  setMax100(
                    event.attendeesD2,
                    +(event.finalRegistrantsD2
                      ? event.finalRegistrantsD2
                      : event.registrantsD2)
                  )
                }}%)
              </span>
            </span>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center">
        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center">
        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: 300px"
    >
      <div class="text-break d-flex align-items-center">
        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: 300px"
    >
      <div class="text-break oneLineText">
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break d-flex align-items-center">
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 200px">
      <div class="text-break d-flex align-items-center">
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break d-flex align-items-center">
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break d-flex align-items-center">
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        <br />
      </div>
    </div>
    <div class="d-flex align-items-center" style="width: 300px">
      <div class="text-break oneLineText">
        <br />
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: 300px"
    >
      <div class="text-break oneLineText">
        <br />
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-end"
      style="min-width: 50px; right: 0"
    >
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
            title="Action"
            (click)="
              actionPop.emit({
                event: event,
                date: 2,
                left: $event.pageX,
                top: $event.pageY
              })
            "
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div>

        <!-- <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0 pointerButton"
          >
            <img src="assets/img/pointer/pointer.svg" />
          </button>
        </div> -->
        <!-- <div>
          <a
            class="btn btn-link w-100 p-0 m-0"
            [routerLink]="['/admin/eventSchedule/eventSchedule/', event.id]"
            title="Edit"
            i18n-title="Edit button"
          >
            <img src="assets/img/edit19x19.svg" />
          </a>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-link w-100 p-0 px-1 m-0"
            data-bs-target="#esDeleteConfirmModal"
            (click)="deleteConfirmation.emit({ entity: event, date: 1 })"
            title="Delete"
            i18n-title="Delete button"
          >
            <img src="assets/img/delete18x20.svg" />
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div *ngIf="event.secondReschedules?.length">
    <div *ngFor="let secondEv of event.secondReschedules">
      <app-es-finance-double-row
        [event]="secondEv"
        (calcFinances)="calcFinances.emit($event)"
        [paddStart]="paddStart + 20"
        [mainParentId]="mainParentId"
        (deleteConfirmation)="deleteConfirmation.emit($event)"
        (enterPayStats)="enterPayStats.emit($event)"
        (showModal)="showModal.emit($event)"
        (actionPop)="actionPop.emit($event)"
      ></app-es-finance-double-row>
    </div>
  </div>
</div>

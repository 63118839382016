import { Component, OnInit } from '@angular/core';
import {
  CirclesOptions,
  CirclesOptionsKm,
  ICompanyLocation,
  ICompanyMarker,
  IMapInfo,
} from '../../../../state/companies.repository';
import { DatalistOption } from '../../../shared/components/datalist-select/datalist-select.component';
import { UnauthorizedCompaniesRepository } from '../../../../state/unauthorized-companies.repository';
import { UnauthorizedCompaniesService } from '../../../../state/unauthorized-companies.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-maps-page',
  templateUrl: './maps-page.component.html',
  styleUrls: ['./maps-page.component.scss'],
})
export class MapsPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    public service: UnauthorizedCompaniesService,
    public repository: UnauthorizedCompaniesRepository
  ) {}
  id: string | null = null;
  selectedCompany: ICompanyMarker | null = null;
  formattedaddress = ' ';
  myOptions: any;
  zoomInfo: IMapInfo | null = null;
  locations: ICompanyLocation[] = [];
  locationIndex: number = -1;
  companyIndex: number = -1;
  zoom: number = 5;
  searchText: any;
  useKmAsValues: boolean = true;
  //USA
  lat: number = 39.5;
  lng: number = -98.35;
  CirclesOptions: DatalistOption[] = CirclesOptionsKm;
  markers: Marker[] = [];
  circles: Circle[] = [];
  companies?: ICompanyMarker[] = undefined;
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('tenant');
        if (this.id) {
          this.loadAllAndSetValue();
        }
      },
    });
  }

  pushMarker(location: ICompanyLocation) {
    if (this.markers) {
      if (!this.markers.find((z) => z.id == location.id)) {
        this.markers.push({
          id: location.id,
          label: location.title,
          lat: location.latitude,
          lng: location.longitude,
          lastEventDate: location.formatedLastEventDate,
          lastEventDesc: location.lastEventDescription,
          draggable: false,
        });
      }
    }
  }

  pushCircle(location: ICompanyLocation) {
    if (this.circles) {
      if (!this.circles.find((z) => z.id == location.id)) {
        this.circles.push({
          id: location.id,
          radius: location.radius,
          lat: location.latitude,
          lng: location.longitude,
          fillColor: 'red',
          draggable: false,
        });
      }
    }
  }

  loadAllAndSetValue() {
    this.service.loadAll(this.id).subscribe((x) => {
      this.companies = x;
      this.service.loadMapInfo(this.id).subscribe((y) => {
        this.zoomInfo = y;
        this.zoom = y.zoom;
        this.lat = y.startingLat;
        this.lng = y.startingLng;
        this.useKmAsValues = y.useKmAsValues;
        if (y.useKmAsValues) {
          this.CirclesOptions = CirclesOptionsKm;
        } else {
          this.CirclesOptions = CirclesOptions;
        }
      });
      this.companies.forEach((y) => {
        y.allCompaniesSelected = true;
        y.locationsHiden = true;
        if (y.companyLocations) {
          this.locations = this.locations.concat(y.companyLocations);
        }
      });
      this.locations.forEach((z) => {
        z.checked = false;
        if (!z.defaultRadius) {
          if (this.useKmAsValues) {
            z.defaultRadius = 3000.0;
          } else {
            z.defaultRadius = 4828.03;
          }
        }
        if (!z.radius) {
          if (this.useKmAsValues) {
            z.radius = 3000.0;
          } else {
            z.radius = 4828.03;
          }
        }
        z.checked = true;
        let loc = this.locations.find((x) => x.id == z.id);
        if (loc) {
          this.pushMarker(loc);
          this.pushCircle(loc);
        }
      });
    });
  }

  clickedMarker(label: string, index: number) {
    //console.log(`clicked the marker: ${label || index}`)
  }

  mapClicked($event: MouseEvent) {}

  markerDragEnd(m: Marker, $event: MouseEvent) {
    //console.log('dragEnd', m, $event);
  }
}

// just an interfaces for type safety.
interface Marker {
  id: string;
  lat: number;
  lng: number;
  label: string;
  draggable: boolean;
  lastEventDesc: string;
  lastEventDate: string;
}

interface Circle {
  id: string;
  lat: number;
  lng: number;
  radius: number;
  draggable: boolean;
  fillColor: string;
}

<div class="d-flex justify-content-center py-4">
  <div class="lp-container p-3 pb-4">
    <h2 class="mb-5">Landing page template</h2>
    <div class="d-flex w-100">
      <div class="col-7 d-flex flex-column align-items-center">
        <div class="position-relative main-image">
          <img
            *ngIf="!imageLoading; else loader"
            [src]="
              template.imageUrl?.length && isValidImage
                ? sanitizer.bypassSecurityTrustResourceUrl(
                    template.imageUrl ?? ''
                  )
                : '/assets/img/image-placeholder.jpeg'
            "
            alt="Main image"
          />
          <app-text-editor-modal
            *ngIf="!imageLoading"
            [title]="'Image url'"
            [forImageUrl]="true"
            [editorContent]="template.imageUrl ?? ''"
            (text)="checkImage($event); updateValues()"
          />
          <ng-template #loader>
            <div
              class="w-100 h-100 d-flex justify-content-center align-items-center"
            >
              <div class="spinner-border" role="status"></div>
            </div>
          </ng-template>
        </div>
        <div style="width: 500px" class="mt-4 min-height-50 position-relative">
          <div
            class="lp-text h-100"
            [innerHTML]="
              template.signUpPrompt?.length
                ? sanitizer.bypassSecurityTrustHtml(template.signUpPrompt ?? '')
                : '*Text below image*'
            "
          ></div>
          <app-text-editor-modal
            [title]="'Text below image'"
            [editorContent]="template.signUpPrompt ?? ''"
            (text)="template.signUpPrompt = $event; updateValues()"
          />
        </div>
      </div>
      <div class="col-5 d-flex flex-column px-2 user-select-none">
        <h1 class="mb-4">Campbell Community Center</h1>
        <h3 style="font-weight: 400; font-size: 22px" class="mb-3">
          Topic:
          <u
            ><b>{{ topic?.length ? topic : "Default template topic" }}</b></u
          >
        </h3>
        <h3 style="font-size: 22px; font-weight: normal" class="mb-4">
          1 W Campbell Ave, Campbell, CA 95008
        </h3>
        <div class="d-flex">
          <img
            src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678116-calendar-128.png"
            width="75"
            height="75"
            class="me-2"
          />

          <div>
            <h3 style="color: rgb(61, 60, 60); font-size: 22px">
              <b>{{ "Tuesday, December 3 @ 6:30 - 8:00 pm" }}</b>
            </h3>
            <div style="color: rgb(66, 185, 159); font-size: 18px">
              <b>OPEN</b>
            </div>
          </div>
        </div>
        <div class="fake-button">REGISTER</div>
      </div>
    </div>
    <hr />
    <div class="d-flex w-100">
      <div class="col-8 position-relative me-2" style="min-height: 300px">
        <div
          class="lp-text h-100 px-2 add-spacing"
          [innerHTML]="
            template.introductionText?.length
              ? sanitizer.bypassSecurityTrustHtml(
                  template.introductionText ?? ''
                )
              : '*Body text*'
          "
        ></div>
        <app-text-editor-modal
          [title]="'Body text'"
          [largeTextField]="true"
          [editorContent]="template.introductionText ?? ''"
          (text)="template.introductionText = $event; updateValues()"
        />
      </div>
      <div class="w-100 position-relative" style="min-height: 300px">
        <div
          class="lp-text px-2 h-100 add-spacing"
          [innerHTML]="
            template.eventContent?.length
              ? sanitizer.bypassSecurityTrustHtml(template.eventContent ?? '')
              : '*Bulletpoints*'
          "
        ></div>
        <app-text-editor-modal
          [title]="'Bulletpoints'"
          [largeTextField]="true"
          [editorContent]="template.eventContent ?? ''"
          (text)="template.eventContent = $event; updateValues()"
        />
      </div>
    </div>
    <div class="w-100 mt-4 lp-text disclaimer position-relative">
      <div class="w-100 min-height-50">
        <div
          class="px-2"
          [innerHTML]="
            template.disclaimer?.length
              ? sanitizer.bypassSecurityTrustHtml(template.disclaimer ?? '')
              : '*Disclaimer*'
          "
        ></div>
      </div>
      <app-text-editor-modal
        [title]="'Disclaimer'"
        [forDisclaimer]="true"
        [largeTextField]="true"
        [editorContent]="template.disclaimer ?? ''"
        (text)="template.disclaimer = $event; updateValues()"
      />
    </div>
    <hr />
  </div>
</div>
<app-text-messages-content
  [forCustom]="forCustom"
  [forDinnerLunch]="forDinnerLunch"
  [forWorkshop]="forWorkshop"
  [content]="template?.reminderContent ?? []"
  (contentChange)="template.reminderContent = $event; updateValues()"
/>

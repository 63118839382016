<div class="mb-5 d-flex justify-content-center">
  <div class="col-5 d-flex flex-column align-items-center" *ngIf="forWorkshop">
    <div class="d-flex flex-wrap gap-2 col-11">
      <h4 class="col-12 text-center">Reminder process - Workshops</h4>
      <div class="blue-button col-6">
        <div class="position-relative">
          <app-text-editor-modal
            [title]="'Confirmation - Email'"
            [largeTextField]="true"
            [editorContent]="findValue('email-confirmation', 0)?.text ?? ''"
            (text)="updateContent('email-confirmation', 0, $event)"
          />
          <p>Confirmation - Email</p>
        </div>
        <mat-checkbox
          *ngIf="forCustom"
          type="checkbox"
          [checked]="findValue('email-confirmation', 0)?.enabled ?? true"
          (change)="log('email-confirmation', 0, $event.checked)"
        />
      </div>

      <div class="blue-button flex-grow">
        <div class="position-relative">
          <app-text-editor-modal
            [forTextOnly]="true"
            [editorContent]="findValue('text-confirmation', 0)?.text ?? ''"
            [title]="'Confirmation - Text/SMS'"
            (text)="updateContent('text-confirmation', 0, $event)"
          />
          <p>Confirmation - Text/SMS</p>
        </div>
        <mat-checkbox
          *ngIf="forCustom"
          type="checkbox"
          [checked]="findValue('text-confirmation', 0)?.enabled ?? true"
          (change)="log('text-confirmation', 0, $event.checked)"
        />
      </div>
      <div class="blue-button col-12">
        <div class="position-relative">
          <app-text-editor-modal
            [forTextOnly]="true"
            [editorContent]="findValue('text-5-day', 0)?.text ?? ''"
            [title]="'5-day text'"
            (text)="updateContent('text-5-day', 0, $event)"
          />
          <p>5-day text</p>
        </div>
        <mat-checkbox
          *ngIf="forCustom"
          type="checkbox"
          [checked]="findValue('text-5-day', 0)?.enabled ?? true"
          (change)="log('text-5-day', 0, $event.checked)"
        />
      </div>
      <div class="blue-button col-12">
        <div class="position-relative">
          <app-text-editor-modal
            [largeTextField]="true"
            [editorContent]="findValue('email-2-day', 0)?.text ?? ''"
            [title]="'2-day email'"
            (text)="updateContent('email-2-day', 0, $event)"
          />
          <p>2-day email</p>
        </div>
        <mat-checkbox
          *ngIf="forCustom"
          type="checkbox"
          [checked]="findValue('email-2-day', 0)?.enabled ?? true"
          (change)="log('email-2-day', 0, $event.checked)"
        />
      </div>

      <div class="blue-button col-12">
        <div class="position-relative">
          <app-text-editor-modal
            [forTextOnly]="true"
            [editorContent]="findValue('text-same-day', 0)?.text ?? ''"
            [title]="'Same day/Day-off text'"
            (text)="updateContent('text-same-day', 0, $event)"
          />
          <p>Same day/Day-off text</p>
        </div>
        <mat-checkbox
          *ngIf="forCustom"
          type="checkbox"
          [checked]="findValue('text-same-day', 0)?.enabled ?? true"
          (change)="log('text-same-day', 0, $event.checked)"
        />
      </div>
    </div>
  </div>
  <div
    class="col-7 d-flex flex-column align-items-center"
    *ngIf="forDinnerLunch"
  >
    <div class="d-flex flex-wrap gap-2 col-11">
      <h4 class="col-12 text-center">Reminder process - Dinner/Lunch</h4>
      <div class="blue-button col-6">
        <div class="position-relative">
          <app-text-editor-modal
            [largeTextField]="true"
            [editorContent]="findValue('email-thank-you', 1)?.text ?? ''"
            [title]="'Thank You - Email'"
            (text)="updateContent('email-thank-you', 1, $event)"
          />
          <p>"Thank You" Email</p>
        </div>
        <mat-checkbox
          type="checkbox"
          *ngIf="forCustom"
          [checked]="findValue('email-thank-you', 1)?.enabled ?? true"
          (change)="log('email-thank-you', 1, $event.checked)"
        />
      </div>

      <div class="blue-button flex-grow">
        <div class="position-relative">
          <app-text-editor-modal
            [forTextOnly]="true"
            [editorContent]="findValue('text-thank-you', 1)?.text ?? ''"
            [title]="'Thank You - Text/SMS'"
            (text)="updateContent('text-thank-you', 1, $event)"
          />
          <p>"Thank You" Text/SMS</p>
        </div>
        <mat-checkbox
          *ngIf="forCustom"
          type="checkbox"
          [checked]="findValue('text-thank-you', 1)?.enabled ?? true"
          (change)="log('text-thank-you', 1, $event.checked)"
        />
      </div>
      <div class="col-12 text-center">
        <img
          src="/assets/img/braces/horizontal_brace2.png"
          alt="brace"
          style="margin-left: 7px"
          height="45px"
          class="col-6"
        />
      </div>
      <div class="d-flex col-12">
        <div class="col-6 me-2">
          <h5 class="col-12 text-center">Non-qualified</h5>
          <div class="blue-button non-qualified mb-2 col-12">
            <div class="position-relative">
              <app-text-editor-modal
                [largeTextField]="true"
                [editorContent]="
                  findValue('email-non-qualified', 1)?.text ?? ''
                "
                [title]="'Non-qualified Email'"
                (text)="updateContent('email-non-qualified', 1, $event)"
              />
              <p>Non-qualified Email</p>
            </div>
            <mat-checkbox
              *ngIf="forCustom"
              type="checkbox"
              [checked]="findValue('email-non-qualified', 1)?.enabled ?? true"
              (change)="log('email-non-qualified', 1, $event.checked)"
            />
          </div>

          <div class="blue-button non-qualified col-12">
            <div class="position-relative">
              <app-text-editor-modal
                [forTextOnly]="true"
                [editorContent]="findValue('text-non-qualified', 1)?.text ?? ''"
                [title]="'Non-qualified Text/SMS'"
                (text)="updateContent('text-non-qualified', 1, $event)"
              />
              <p>Non-qualified Text/SMS</p>
            </div>
            <mat-checkbox
              *ngIf="forCustom"
              type="checkbox"
              [checked]="findValue('text-non-qualified', 1)?.enabled ?? true"
              (change)="log('text-non-qualified', 1, $event.checked)"
            />
          </div>
        </div>
        <div class="d-flex flex-wrap flex-grow">
          <h5 class="text-center col-12">Qualified</h5>
          <div class="blue-button col-6 me-2 mb-2">
            <div class="position-relative">
              <app-text-editor-modal
                [largeTextField]="true"
                [editorContent]="findValue('email-confirmation', 1)?.text ?? ''"
                [title]="'Confirmation Email'"
                (text)="updateContent('email-confirmation', 1, $event)"
              />
              <p>Confirmation - Email</p>
            </div>
            <mat-checkbox
              *ngIf="forCustom"
              type="checkbox"
              [checked]="findValue('email-confirmation', 1)?.enabled ?? true"
              (change)="log('email-confirmation', 1, $event.checked)"
            />
          </div>
          <div class="blue-button flex-grow mb-2">
            <div class="position-relative">
              <app-text-editor-modal
                [forTextOnly]="true"
                [editorContent]="findValue('text-confirmation', 1)?.text ?? ''"
                [title]="'Workshop Confirmation Text'"
                (text)="updateContent('text-confirmation', 1, $event)"
              />
              <p>Confirmation - Text</p>
            </div>
            <mat-checkbox
              *ngIf="forCustom"
              type="checkbox"
              [checked]="findValue('text-confirmation', 1)?.enabled ?? true"
              (change)="log('text-confirmation', 1, $event.checked)"
            />
          </div>
          <div class="blue-button col-12 mb-2">
            <div class="position-relative">
              <app-text-editor-modal
                [largeTextField]="true"
                [editorContent]="findValue('email-2-day', 1)?.text ?? ''"
                [title]="'2-day email reminder'"
                (text)="updateContent('email-2-day', 1, $event)"
              />
              <p>2-day email reminder</p>
            </div>
            <mat-checkbox
              *ngIf="forCustom"
              type="checkbox"
              [checked]="findValue('email-2-day', 1)?.enabled ?? true"
              (change)="log('email-2-day', 1, $event.checked)"
            />
          </div>
          <div class="blue-button col-12">
            <div class="position-relative">
              <app-text-editor-modal
                [forTextOnly]="true"
                [editorContent]="findValue('text-same-day', 1)?.text ?? ''"
                [title]="'Same day text reminder'"
                (text)="updateContent('text-same-day', 1, $event)"
              />
              <p>Same day text reminder</p>
            </div>
            <mat-checkbox
              *ngIf="forCustom"
              type="checkbox"
              [checked]="findValue('text-same-day', 1)?.enabled ?? true"
              (change)="log('text-same-day', 1, $event.checked)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinPipe } from './pipes/join.pipe';
import { NgVarDirective } from './directives/ng-var.directive';
import { SortPipe } from './pipes/sort.pipe';
import { FindByPipe } from './pipes/find-by.pipe';
import { PropPipe } from './pipes/prop.pipe';
import { TimingPipe } from './pipes/timing.pipe';
import { ValidateStateDirective } from './directives/validate-state.directive';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { SimpleCardComponent } from './components/simple-card/simple-card.component';
import { DatalistSelectComponent } from './components/datalist-select/datalist-select.component';
import { RolePinComponent } from './components/role-pin/role-pin.component';
import { EntityAvatarComponent } from './components/entity-avatar/entity-avatar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SortDropdownComponent } from './components/sort-dropdown/sort-dropdown.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarPipe } from './pipes/calendar.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { GoogleMapsLinkComponent } from './components/google-maps-link/google-maps-link.component';
import { StatCardComponent } from './components/stat-card/stat-card.component';
import { ImportExportComponent } from './components/import-export/import-export.component';
import { TranslateRolesPipe } from './pipes/translate-roles.pipe';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SortTableComponent } from './components/sort-table/sort-table.component';
import { EntityAvatarInitComponent } from './components/entity-avatar-init/entity-avatar-init.component';
import { CustomPaginationComponent } from './components/custom-pagination/custom-pagination.component';
import { UsersDatalistSelectComponent } from './components/users-datalist-select/users-datalist-select.component';
import { SearchComponent } from './components/search/search.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TextEditorModalComponent } from './components/text-editor-modal/text-editor-modal.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [
    JoinPipe,
    NgVarDirective,
    SortPipe,
    FindByPipe,
    PropPipe,
    TimingPipe,
    ValidateStateDirective,
    StripHtmlPipe,
    SimpleCardComponent,
    DatalistSelectComponent,
    RolePinComponent,
    EntityAvatarComponent,
    SpinnerComponent,
    SortDropdownComponent,
    ErrorAlertComponent,
    CalendarPipe,
    PaginationComponent,
    ScrollSpyDirective,
    GoogleMapsLinkComponent,
    StatCardComponent,
    ImportExportComponent,
    TranslateRolesPipe,
    ImageUploaderComponent,
    ImageSliderComponent,
    ImageCardComponent,
    ConfirmDeleteComponent,
    SortTableComponent,
    EntityAvatarInitComponent,
    UsersDatalistSelectComponent,
    CustomPaginationComponent,
    SearchComponent,
    TextEditorModalComponent,
  ],
  imports: [
    InfiniteScrollModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    CKEditorModule,
  ],
  exports: [
    InfiniteScrollModule,
    JoinPipe,
    NgVarDirective,
    SortPipe,
    FindByPipe,
    PropPipe,
    TimingPipe,
    ValidateStateDirective,
    StripHtmlPipe,
    SimpleCardComponent,
    DatalistSelectComponent,
    UsersDatalistSelectComponent,
    RolePinComponent,
    EntityAvatarComponent,
    SortDropdownComponent,
    ErrorAlertComponent,
    CalendarPipe,
    BrowserAnimationsModule,
    PaginationComponent,
    ScrollSpyDirective,
    GoogleMapsLinkComponent,
    StatCardComponent,
    ImportExportComponent,
    ImageUploaderComponent,
    TranslateRolesPipe,
    ImageSliderComponent,
    ImageCardComponent,
    ConfirmDeleteComponent,
    EntityAvatarInitComponent,
    CustomPaginationComponent,
    SearchComponent,
    SpinnerComponent,
    TextEditorModalComponent,
  ],
})
export class SharedModule {}

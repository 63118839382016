import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IReminderContent,
  ReminderContentType,
} from 'src/app/state/event-schedule.repository';

@Component({
  selector: 'app-text-messages-content',
  templateUrl: './text-messages-content.component.html',
  styleUrls: ['./text-messages-content.component.scss'],
})
export class TextMessagesContentComponent {
  @Input() forWorkshop = false;
  @Input() forDinnerLunch = false;
  @Input() forCustom = false;
  @Input() content: IReminderContent[] = [];
  @Output() contentChange = new EventEmitter<IReminderContent[]>();

  findValue(type: ReminderContentType, eventType: 0 | 1) {
    return this.content?.find(
      (x) => x.type === type && x.eventType === eventType
    );
  }

  updateContent(type: ReminderContentType, eventType: 0 | 1, text: string) {
    let editedValue = this.content.find(
      (x) => x.type === type && x.eventType === eventType
    );
    if (editedValue) {
      if (!text.length) {
        this.content = this.content.filter((x) => x.id !== editedValue?.id);
      } else {
        editedValue.text = text;
      }
    } else
      this.content.push({
        type,
        eventType,
        text,
      });
    this.contentChange.emit(this.content);
  }

  log(type: ReminderContentType, eventType: 0 | 1, enabled: boolean) {
    let editedValue = this.content.find(
      (x) => x.type === type && x.eventType === eventType
    );
    if (editedValue) {
      editedValue.enabled = enabled;
    } else
      this.content.push({
        type,
        eventType,
        text: '',
        enabled,
      });
    this.contentChange.emit(this.content);
  }
}

import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IWebContent } from 'src/app/state/event-schedule.repository';
@Component({
  selector: 'app-landing-page-form',
  templateUrl: './landing-page-form.component.html',
  styleUrls: ['./landing-page-form.component.scss'],
})
export class WebContentFormComponent {
  isValidImage = true;
  imageLoading = false;
  template: IWebContent = {};
  constructor(
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: any
  ) {}
  @Input() forWorkshop = false;
  @Input() forDinnerLunch = false;
  @Input() forCustom = false;
  @Input() topic: string = '';
  @Input() set dataset(input: IWebContent | null | undefined) {
    this.template = structuredClone(input) ?? {};
    this.checkImage(this.template?.imageUrl ?? '');
  }
  @Output() landingPageData: EventEmitter<Partial<IWebContent>> =
    new EventEmitter();

  checkImage(url: string): Promise<boolean> {
    this.imageLoading = true;

    const processedUrl = this.convertGDriveUrl(url);
    this.template.imageUrl = processedUrl;
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        this.isValidImage = true;
        this.imageLoading = false;
        resolve(true);
      };
      img.onerror = () => {
        this.isValidImage = false;
        this.imageLoading = false;
        resolve(false);
      };
      img.src = processedUrl;
    });
  }

  convertGDriveUrl(url: string): string {
    const gDriveBaseUrl = 'https://lh3.googleusercontent.com/d/';
    const gDriveMatch =
      /https:\/\/drive\.google\.com\/file\/d\/([a-zA-Z0-9_-]+)\/view/;
    const match = url.match(gDriveMatch);

    if (match && match[1]) {
      return `${gDriveBaseUrl}${match[1]}`;
    }
    return url;
  }

  updateValues() {
    this.landingPageData.emit(this.template);
  }
}

<div class="row">
  <h1 class="mb-4 col" i18n="Create event topic label">
    {{ id === "new" ? "Create" : "Update" }} event Template
  </h1>
  <div class="col-6 p-0 d-flex justify-content-end">
    <div class="d-flex me-2" *ngIf="id !== 'new'">
      <a
        [routerLink]="['/admin/topics/', id, 'duplicate']"
        class="d-flex justify-content-center btn dark-wfrBlue text-white"
        style="width: 140px !important; height: 40px !important"
      >
        <img src="assets/img/duplicate-icon.svg" />
        <span class="ms-2">Duplicate</span>
      </a>
    </div>
    <button
      class="btn btn-wfrBlue me-2"
      style="width: 140px; height: 40px"
      (click)="saveContent()"
    >
      Save
    </button>
    <div class="d-flex me-3">
      <a
        routerLink="/admin/topics"
        class="d-flex justify-content-center btn dark-wfrBlue text-white"
        style="
          justify-content: end;
          width: 140px !important;
          height: 40px !important;
        "
      >
        <img src="assets/img/backArrow.svg" />
        <span class="ms-2">Back</span>
      </a>
    </div>
  </div>
</div>

<div *ngIf="id === 'new' || topic" class="row mb-4">
  <div class="col-12">
    <app-topics-editform
      [editedTopic]="topic"
      (TopicSubmit)="createTopic($event)"
    ></app-topics-editform>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert
      title="Could not submit the topic"
      i18n-title="Topic submit error alert"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
